$VERSION: PEAKS;
// @import url(https://fonts.bunny.net/css?family=alatsi:400);
// @import url(https://fonts.bunny.net/css?family=alatsi:400|open-sans:400,700);
/*
VARIABLES
*/

$mobileBreakpoint: 1020px;

$spacing: 8px;
$bigSpace: $spacing * 3;

$headerHeight: 40px;
$headerInner: $headerHeight - $spacing;

$gridSpacing: $spacing * 3/4; // must be divisible by 2

// chunky chocolatey https://offeo.com/learn/earth-tone-color-palette 

// $colorsPeaks: (#912928, #C33426, #ED6841, #FFCC7B);
// $colorsTrekking: (#0E4868, #15659A, #2E9ACE, #B2E5F4);

$colorsPeaks: (
	#912928,
	#a22d27,
	#b23027,
	#c33426,
	#d1452f,
	#df5738,
	#ed6841,
	#f38954,
	#f9ab68,
	#ffcc7b
);
$colorsTrekking: (
	#0e4868,
	#105279,
	#135b89,
	#15659a,
	#1d77ab,
	#2688bd,
	#2e9ace,
	#5ab3db,
	#86cce7,
	#b2e5f4
);
$colorPrimary: yellow;
$colorAccent: purple;

@if $VERSION == 'PEAKS' {
    $colorPrimary: nth($colorsPeaks, 5);
	$colorAccent: nth($colorsPeaks, 10);
} @else if $VERSION == 'TREKKING' {
    $colorPrimary: nth($colorsTrekking, 5); 
	$colorAccent: nth($colorsTrekking, 10);
} @else {
    $colorPrimary: yellow; // Fallback color
	$colorAccent: purple;
}

$font-inter: 'Inter', sans-serif; // From next/font
$font-geologica: 'Geologica', sans-serif; // Manually added

/*
RESETS / BASE
*/

body{
	margin: 0;
	// display: grid;
	// grid-template-rows: auto 1fr;
}

* {
	// font-family: var(--font-inter);
	font-family: $font-inter;
}

.clear-fix:after {
	content: "";
	display: table;
	clear: both;
}

h1,h2,h3,h4,h5,h6 {
	margin-top: 0;
	margin-bottom: $spacing * 2;
	font-weight: 300 !important;
	// font-family: var(--font-geologica);
	font-family: $font-geologica;

	a {
		font-family: $font-geologica;
	}
} 

p {margin-top: 0;}

a, a:visited, a:hover, a:focus {
	color: $colorPrimary;
	text-decoration: none;
	// font-weight: 550;

	&.blank-link {
		color: #fff;
	}
}
.white {
	color: #fff;
}
label, .label {
	font-family: $font-geologica;
} 

.mantine-ButtonGroup-root {
	.mantine-Button-root {
		border-right-width: 0 !important;

		&:last-of-type {
			border-right-width: 1px !important;
		}
	}
}

.hide-map-breakpoint {
	display: block;
}
@media (max-width: 992px) {
	.hide-map-breakpoint {
		display: none;
	}
}

/*
Main UI
*/
#homepage {
	

	.wrapper {
		position: relative;
		
		background-size: cover;
		background-position: center;

		$mobileBreakpoint: 1020px;

		.inner {
			padding-top:  25px;
			padding-bottom: 50px;
			
			text-shadow: -1px -1px 8px #000;

			@media (min-width: $mobileBreakpoint) {
				padding-top: 55px;
				padding-bottom: 130px;
			}

			$smallScreenForText: 550px;

			@media (max-width: $smallScreenForText) {
				h1 {
					font-size: 26px;
				}
				h2 {
					font-size: 22px;
				}
				p,ul {
					font-size: 16px;
				}
			}
			
			.seven-summits-container {
				width: 100%;
				max-width: 100%;
				color: #ddd;
				
				.seven-summits-list {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					padding-left: 0;
					list-style-type: none;
					width: 100%;
					max-width: 100%;
					padding: 0;
					
					li {
						margin: 0 20px 12px;
						position: relative;
						padding-left: 16px;
						
						&::before {
							content: "•";
							position: absolute;
							left: 0;
							color: $colorPrimary;
							font-size: 27px;
							top: 50%;
							transform: translateY(-50%);
							line-height: 0;
						}
						
						h3 {
							margin-bottom: 0;
							white-space: nowrap;
						}
					}
					
					@media (max-width: $mobileBreakpoint) {
						flex-direction: column;
						align-items: center;
						
						li {
							margin: 0 0 12px;
							width: 90%;
							text-align: center;
							position: relative;
							display: flex;
							align-items: center;
							justify-content: center;
							
							&::before {
								content: "•";
								position: static;
								display: inline-block;
								margin-right: 10px;
								color: $colorPrimary;
								font-size: 27px;
								flex-shrink: 0;
							}
							
							h3 {
								white-space: normal;
								font-size: 20px;
								line-height: 1.3;
								display: inline;
								margin: 0;
							}
						}
					}
					
					@media (max-width: 767px) {
						li {
							text-align: left;
							width: 85%;
							justify-content: flex-start;
						}
					}
					
					@media (max-width: 500px) {
						width: 100%;
						
						li {
							width: 90%;
							
							h3 {
								font-size: 18px;
							}
						}
					}
				}
			}
		}
	  
		
	}
	  
	.inner {
		position: relative;
		z-index: 1;
	}

	$mantine-color-white: #fff;
	$mantine-color-primary-4: $colorPrimary;
	$mantine-color-gray-0: silver;
	  
	.title {
		font-weight: 800 !important;
		font-size: 40px;
		letter-spacing: -1px;
		padding-left: $spacing;
		padding-right: $spacing;
		color: $mantine-color-white;
		margin-bottom: $spacing;
		text-align: center;
		// font-family: Greycliff CF, var(--mantine-font-family);
	  
		@media (max-width: $mobileBreakpoint) {
			font-size: rem(28px);
			// text-align: left;
		}
	}
	  
	.highlight {
		color: $mantine-color-primary-4;
	}
	  
	.description {
		color: $mantine-color-gray-0;
		// text-align: center;
	  
		@media (max-width: $mobileBreakpoint) {
			font-size: var(--mantine-font-size-md);
			// text-align: left;
		}
	}
	  
	.controls {
		margin-top: $spacing * 1.5;
		display: flex;
		flex-direction: row;
		gap: $spacing * 4;
		justify-content: center;
		padding-left: $spacing;
		padding-right: $spacing;
		align-items: center;
	  
		@media (max-width: 560px) {
			flex-direction: column;
			gap: $spacing * 2;
		}
	}
	  
	.control {
		height: rem(42px);
		font-size: var(--mantine-font-size-md);
	  
		&:not(:first-of-type) {
			margin-left: $spacing;
		}
	  
		@media (max-width: $mobileBreakpoint) {
			&:not(:first-of-type) {
				margin-top: $spacing;
				margin-left: 0;
		  	}
		}
	}
	  
	.secondaryControl {
		color: $mantine-color-white;
		background-color: rgba(255, 255, 255, 0.4);
	  
		@mixin hover {
		  background-color: rgba(255, 255, 255, 0.45);
		}
	}

	.trip-carousel {
		max-width: 100%;
		overflow: hidden;

		.price {
			color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
		}
		  
		.carousel {
			@mixin hover {
				.carouselControls {
					opacity: 1;
				}
			}
		}
		  
		.carouselControls {
			transition: opacity 150ms ease;
			opacity: 0;
		}
		  
		.carouselIndicator {
			width: rem(4px);
			height: rem(4px);
			transition: width 250ms ease;
		  
			&[data-active] {
				width: rem(16px);
			}
		}
	}

	.homepage-guides-overview {
		.guide-overview-item {
			img {
				opacity: .4;
				filter: grayscale(40%);

				&:hover {
					opacity: 1;
					filter: grayscale(0%);
				}
			}
		}
	}


	.articles-carousel {
		max-width: 100%;
		overflow: hidden;

		.card {
			height: 440px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			background-size: cover;
			background-position: center;
		  }
		  
		  .title {
			// font-family: Greycliff CF, sans-serif;
			font-weight: 900;
			color: #ffffff; /* Replaced white variable with hex value */
			line-height: 1.2;
			font-size: 32px;
			margin-top: 8px; /* Sensible replacement for spacing */
		  }
		  
		  .category {
			color: #ffffff; /* Replaced white variable with hex value */
			opacity: 0.7;
			font-weight: 700;
			text-transform: uppercase;
		  }
		  
	}

	.trip-map-col {
		// .trip-map {
		// 	max-width: 100%;
		// 	height: auto;
		// 	border-radius: $spacing;
		// }
	}
	.countries-section {
		
	}

	.how-it-works-section {
		.how-it-works-row {
			display: grid;
			grid-template-columns: 300px 1fr;
			gap: 32px;
			margin-bottom: 32px;
			align-items: center;
			background: rgba(255, 255, 255, 0.8);
			padding: 24px;
			border-radius: 12px;
			transition: all 0.2s ease;
			border: 1px solid #eee;
			box-shadow: 0 2px 4px rgba(0,0,0,0.05);

			&:hover {
				transform: translateY(-2px);
				box-shadow: 0 4px 12px rgba(0,0,0,0.1);
				border-color: $colorPrimary;
			}

			@media (max-width: $mobileBreakpoint) {
				grid-template-columns: 1fr;
				text-align: center;
				padding: 16px;
			}

			.image-container {
				position: relative;
				width: 100%;
				height: auto;
				border-radius: 8px;
				overflow: hidden;
				box-shadow: 0 4px 8px rgba(0,0,0,0.1);

				@media (max-width: $mobileBreakpoint) {
					margin: 0 auto;
					max-width: 300px;
				}

				img {
					display: block;
					width: 100%;
					height: auto;
					object-fit: contain;
				}
			}

			.content {
				h3 {
					margin: 0;
					font-size: 1.5rem;
					color: $colorPrimary;
					margin-bottom: 12px;
				}

				p {
					margin: 0;
					font-size: 1.1rem;
					line-height: 1.6;
					color: #666;
				}
			}
		}

		// Add spacing between mountaineer and guide sections
		& > .Title {
			margin-top: 48px;
			margin-bottom: 24px;
		}
	}
}

.trip-map {
	max-width: 100%;
	height: auto;
	border-radius: $spacing;
}

.container {
	display: flex;
    flex-direction: column;
    
	&.fixed-100-percent-height {
		height: calc(100vh - 61px);
		// padding-bottom: 61px;

		#header {
			margin-bottom: $spacing * 2;
		}
	}

	&.page-column {
		width: 100%;
		max-width: 1100px;
		margin: 0 auto;
		padding-left: $spacing;
		padding-right: $spacing;
		margin-top: $spacing * 2;	
	}

	&.no-top-margin {
		margin-top: 0px;
	}
}

#admin-header {
	height: 60px;
	padding: $spacing / 2;
	box-shadow: 0px 4px 16px 4px rgba(0,0,0,0.2);
	display: flex;
	justify-content: space-between;

	#header-left {
		display: flex;
    	flex-direction: row;
		align-items: center;
		gap: $spacing*2;

		#brand {
			display: inline-block;
			// font-family: var(--font-alatsi);

			img {
				display: inline-block;
				height: $headerInner;
				width: auto;
			}

			.brand-header-text {
				font-size: 24px;
				font-weight: 500;
				display: inline-block;
				line-height: $headerInner;
				vertical-align: top;
				margin-left: $spacing;
			}

			#brand-title {
				color: black;
			}

			#tagline {
				color: grey;
			}
		}
	}

	#top-right-buttons {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: $spacing;
		gap: $spacing;

		#account-button , #unit-toggle {
			display: inline-block;
		}
	}
}

#home-page-header {
	font-size: 24px;
	margin-bottom: $spacing;

	padding-left: $spacing;
	padding-right: $spacing;
}

#trip-page-blurb {
	margin-bottom: $spacing;
	padding-left: $spacing;
	padding-right: $spacing;
}

#header-search {

	padding-left: $spacing;
	padding-right: $spacing;

	#home-page-alert {
		margin-bottom: $spacing * 2;
	}

	#search-form {
		margin: 0 auto;
	}

	#geo-filtering-tag {
		font-size: smaller;
		font-weight: 600;	
	}
}

#map-results-container {
	padding-left: $spacing;
	padding-right: $spacing;

	flex-grow: 1; /* This makes the main content fill the available space */
    display: flex; /* Make the main content a flex container */

	&.map-mode-enabled {
		overflow-y: auto;
		gap: $spacing;

		#map-results {
			flex: 0.618;
		}
		 #trek-results {
			flex: 1;
		}
	}

	#map-results {
		#map-container {
			height: 100%;
			width: 100%;
			padding: 0 0 $spacing $spacing;
			padding: 0 0 0 0;

			border-radius: $spacing;
		}
	}
	#trek-results {
		overflow-y: auto; /* Add scroll to results if content overflows */
		width: 100%;
	}
}	

.trek-card-container {
	flex: 100%;
	vertical-align: top;

	// on the home page
	&#trek-results {
		height: 100%;
	}

	display: grid;
	position: relative;

	grid-template-columns: repeat(1, 1fr);
	grid-auto-rows: max-content;
	grid-gap: $spacing;
	
	// width / columns
	// 1 column
	.trek-card { .name {
		// color: orange;
		font-size: 24px;
	}}
	// 2 columns
	@media only screen and (min-width: 460px) {
		grid-template-columns: repeat(2, minmax(200px, 2fr));

		.trek-card { .name {
			// font-size: 24px;
			// color: yellow;
		}}
	}
	// 3 columns
	@media only screen and (min-width: 660px) {
		grid-template-columns: repeat(3, minmax(200px, 2fr));

		.trek-card { .name {
			// font-size: 24px;
			// color: green;
		}}
	}
	// 4 columns
	@media only screen and (min-width: 940px) {
		grid-template-columns: repeat(4, minmax(200px, 2fr));
		.trek-card { .name {
			font-size: 26px;
			// color: red;
		}}
	}
	// 5 columns
	@media only screen and (min-width: 1100px) {
		grid-template-columns: repeat(5, minmax(200px, 2fr));
	}
}

#map-results-container.map-mode-enabled {
	.trek-card-container {

		grid-template-columns: repeat(2, minmax(200px, 2fr));

		@media only screen and (min-width: 1020px) {
			grid-template-columns: repeat(3, minmax(200px, 2fr));
		}

		@media only screen and (min-width: 1360px) {
			grid-template-columns: repeat(4, minmax(200px, 2fr));
		}
	}
}

// .article-trek-card-container.trek-card-container {
// 	container-type: inline-size;
// 	grid-template-columns: repeat(1, minmax(200px, 2fr));

// 	@media only screen and (min-width: 640px) {
// 		grid-template-columns: repeat(3, minmax(200px, 2fr));
// 	}
// }


// Search UI

#search-filter-section {
	padding-bottom: $spacing;

	.search-filter-line {
		margin-bottom: $spacing;
	}

	.search-control {
		display: inline-block;
		width: 100%;

		.label {
			font-size: smaller;
			padding-bottom: $spacing / 2;

			svg {
				vertical-align: middle;
				line-height: 100%;
				margin-right: $spacing / 4;
			}
			align-items: center;
			vertical-align: middle;
			// font-weight: 600;
		}

		.toggle-filter-control {
			vertical-align: bottom;
		}

		.mantine-ButtonGroup-root {
			display: flex;

			button {
				flex-grow: 1;
			}
		}
	}

	#budget-filter {
		width: 100%;
		height: 30px;
		display: flex;

		#budget-slider {
			flex-grow: 1;
			display: grid;
			align-items: center;
			padding-top: 6px;
		}
		#budget-input {
			width: 80px;
			margin-left: $spacing * 2;
		}
	}
}

.trek-card {
	position: relative;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: $spacing / 2;
	
	aspect-ratio: 4 / 3;


	// &.is-holding-page-only {
	// 	opacity: .5;
	// }

	a,
	a:hover,
	a:visited,
	a:active {
		cursor: pointer;
		color: #fff;
		// link should cover whole card
		width: 100%;
		height: 100%;
		display: block;
	}

	.summary {
		width: 100%;
		bottom: 0px;
		// background-color: tomato;
	}

	.name {
		text-align: center;
		word-break: keep-all;
	
		position: absolute;
		top: 25%;
		// max-width: 70%;
		margin: auto;
		left: 0;
		right: 0;
	
		.denote-mountain-icon {
			font-size: 64%;
			vertical-align: middle;
		}

	}

	.small-detail {
		color: #fff;
		background-color: rgba(0, 0, 0, 0.4);
		padding: $spacing / 2;
		position: absolute;
		border-radius: $spacing / 2;

		svg {
			vertical-align: middle;
			line-height: 100%;
		}
	}

	.bottom-left {
		left: $spacing / 2;
		bottom: $spacing / 2;
	}
	
	.bottom-right {
		right: $spacing / 2;
		bottom: $spacing / 2;
	}
	
	.top-right {
		right: $spacing / 2;
		top: $spacing / 2;
	}
	
	.top-left {
		left: $spacing / 2;
		top: $spacing / 2;
	}

	&#suggest-a-trip {
		opacity: 0.8;
		border: 2px dashed silver;
		border-radius: $spacing;

		.text {
			color: silver;

			&:hover {
				color: grey;
			}

		}

		&:hover {
			cursor: pointer;
		}
	}

	img.flag {
		margin-left: $spacing / 2;
		margin-top: -4px;
		height: 18px;
		border-radius: $spacing / 2;
		opacity: 0.6;

		&:hover {
			opacity: 1;
		}
	}
}

.text-shadow {
	font-weight: 800;
	text-shadow: -1px -1px $spacing #000;
}

/* PAGE TEMPLATE */
#page-template {
	

}

.bottom-corner-element {
	position: fixed;
	bottom: -1px;
	z-index: 100;
	overflow: hidden;
	border: 1px solid silver;
	border-bottom-width: 0;

	&.right-corner {
		right: -1px;
		border-top-left-radius: 8px;
		border-right-width: 0;	
	}
	&.left-corner {
		left: -1px;
		border-top-right-radius: 8px;
		border-left-width: 0;
	}
}

/* TREK SUMMARY */

#close-modal {
	position: absolute;
	right: $spacing;
	top: $spacing;
}

#trek-summary {

	.hero {
		width: 100%;
		height: 220px;
		background-size: cover;
		background-position: center;
	}

	h2#trek-title {
		margin-top: 0;
		
		.denote-mountain-icon {
			font-size: 66.66%;
			vertical-align: middle;
			margin-right: $spacing;
		}

		.country-flag {
			margin-left: $spacing;
		}
	}

	ul {
		padding-left: 0;
		list-style-type: disc;

		li {
			display: inline-block;
			margin-right: $spacing;
		}
	}

	.trek-overview {
		margin-bottom: $bigSpace;

		.primary-image-tile {
			max-height: 280px;
			width: auto;
			max-width: 60%;
			float: left;
			margin-right: $spacing;
			margin-bottom: $spacing;
		}
	}

	.secondary-image-tile {
		height: 120px;
		width: auto;
		margin-right: $spacing;
		margin-bottom: $spacing / 2;
	}

	.video-wrapper {
		display: inline-block;
		margin-right: $spacing;
		margin-bottom: $spacing / 2;
	}
}

/* NEW TREK PAGE */

#trek-page {
	line-height: 1.7em;

	.edit-trip-tab {
		text-decoration: none;
		
		button { 
			width: 100%;

			span {
				text-transform: capitalize;
			}
		}
	}
}

#footer {
	padding-bottom: $spacing * 8;
	text-align: center;
	font-size: xx-small;
	color: #696969;

	

	#footer-content-wrapper {
		padding-left: $spacing * 6;
		padding-right: $spacing * 6;
		padding-top: 4px;
		display: inline-block;
		width: auto;

		.link-group {
			display: inline-block;
			margin: 0 4px;
		}
	}
}

.container {
	#footer {
		padding-top: 60px;
		padding-bottom: 60px;
	}
}
.container.fixed-100-percent-height {
	#footer {
		padding-top: 4px;
		padding-bottom: 4px;

		@media (max-width: 820px) {
			padding-top: 60px;
			padding-bottom: 60px;
		}
	}
}

.spacing-between-sections-above {
	margin-top: $bigSpace;
}

.section-title {
	display: flex;
	align-items: flex-start;

	margin-top: $spacing * 6;
	margin-bottom: $spacing * 4;

	// gap: $spacing;

	h2 {
		margin-right: $spacing;
		margin-bottom: 0;

		a {
			font-weight: 600;
			text-decoration: none; 
			color: inherit; 

			&:hover, &:visited, &:active {
				text-decoration: none; 
			}
		}
	}

	h3 {
		margin-top: $spacing * 1;
		margin-bottom: 0;
	}

	button {
		vertical-align: top;
	}
}

.picture-grid-wrapper {
	.picture-grid {
		img {
			vertical-align: bottom;
			margin: 0 $spacing $spacing 0;
			max-width: 100%;
			height: auto;
			cursor: pointer;
		}
	}

	&.is-vertical {
		img {
			display: block;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

#overview-wrapper {
	h1 {
		margin-bottom: $spacing;
	}

	h2#also-known-as-header {
		margin-bottom: 0;
		font-size: 16px;
	}

	#breadcrumbs {
		margin-top: $spacing;
		
		.mantine-Breadcrumbs-root {
			flex-wrap: wrap;
			gap: 8px;
			
			a {
				white-space: normal;
				line-height: 1.2;
			}
			
			.mantine-Breadcrumbs-separator {
				margin: 0;
			}
		}
	}

	#trek-one-line-summary {
		display: inline-flex;
		flex-wrap: wrap;
		gap: 12px;
		margin-top: $spacing;
		align-items: baseline;
		
		.one-line-summary-item {
			#countries {
				display: inline-flex;
				gap: $spacing;

				.trip-page-country-badge {
					padding: 0 4px;
				}
			}
		}
	}
}

#images-lightbox.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 101;

	.modalContent {
		display: flex;
		background-color: #fff;
		padding: 20px;
		border-radius: 5px;
		box-shadow: 0 0 10px rgba(0,0,0,0.1);
	}
	
	.closeButton {
		position: absolute;
		top: 10px;
		right: 20px;
		cursor: pointer;
		background: none;
		border: none;
		font-size: 1.5em;
		padding: 0;
		color: #555;
	}
	
	.column {
		flex-grow: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.left, .right {
		flex-basis: 15%;
	}
	
	.middle {
		flex-basis: 70%;
		height: 100%;  /* Takes up all available vertical space */
		display: flex;
		justify-content: center;
		align-items: center; /* Centers the image vertically */
		flex-direction: column; /* Change the direction to column */

		.image-container {
			display: flex;
			flex-direction: column; /* Set direction to column */
			align-items: center; /* Center the content horizontally */
			width: 100%; /* Take full width */
			max-height: 90%; /* Adjust as needed */
			justify-content: center; /* Center the content vertically */
	
			img {
				max-width: 100%;
				object-fit: contain;
				max-height: 90%;
				margin-bottom: 10px; /* Add some space between the image and the button */
				border-radius: $spacing / 2;
			}

			.underneath-image{
				width: 100%;

				.right {
					text-align: right;
				}

				.mantine-Divider-root{
					margin-top: $spacing;
					margin-bottom: $spacing;
				}
			}
		}
	}
	
	.arrowButton {
		font-size: 2em;
		background: none;
		border: none;
		cursor: pointer;
	}
}


#top-image-gallery-wrapper {

	#singlepic-design {
		background-size: 'cover';
		background-repeat: 'no-repeat';
		background-position: 'center center';
		max-height: 325px;
		cursor: 'pointer';

		img {
			max-width: 100%;
			height: auto;

			border-radius: $spacing;
		}
	}

	#multipic-design {
		display: flex;
		height: 325px;
		column-gap: $spacing;

		.column {
			flex: 1;
		}

		#big-pic {
			max-height: 325px;
			cursor: pointer;

			border-bottom-left-radius: $spacing;
			border-top-left-radius: $spacing;

			overflow: hidden;
		}

		#twobytwo {
			height: 100%;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-template-rows: repeat(2, 1fr);
			grid-gap: $spacing;

			div {
				height: 100%;
				cursor: pointer;
				overflow: hidden;
			}
		}
	}
}

#trip-page-picture-upload-button {
	margin-top: $spacing;
	margin-bottom: $spacing * 2;
}

#high-level-info-wrapper {

	.items-wrapper {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		grid-column-gap: $spacing;
		grid-row-gap: $spacing;
		padding-top: $spacing * 2;

		.high-level-info-item {
			display: flex;
			margin-bottom: $spacing * 2;
			// background-color: bisque;

			.icon {
				// background-color: sandybrown;
				stroke-width: 1;
			}

			.remainder {
				flex: 1;
				// background-color: rosybrown;
				padding-left: $spacing * 2;

				ul {
					margin-top: 0;
					margin-bottom: 0;
					padding-left: $spacing * 2;
				}
			}
		}
	}

	.trek-difficulties {
		.trek-difficulty {
			.mantine-Rating-root {
				display: inline-flex;
				vertical-align: text-top;
			}
		}
	}
}

// #image-gallery-wrapper {

// 	.gallery-image-tile {
// 		vertical-align: bottom;
// 		margin: 0 $spacing $spacing 0;
// 		cursor: pointer;
// 	}

// 	img {
// 		max-width: 100%;
// 		height: auto;
// 	}
// }

#guides-wrapper {
	#guides-table {
		.guide-offering-overview {

			padding: 12px 0;

			$smallBreakpoint: 767px;
			$mediumBreakpoint: 1024px;


			.guide-offering-overview-small,
			.guide-offering-overview-medium,
			.guide-offering-overview-large {
				display: none; // Hide all by default
			}

			// Small screens (mobile)
			@media (max-width: $smallBreakpoint) {
				.guide-offering-overview-small {
					display: grid;
					// background-color: red;
				}
			}

			// Medium screens
			@media (min-width: #{$smallBreakpoint + 1}) and (max-width: $mediumBreakpoint) {
				.guide-offering-overview-medium {
					display: grid;
					// background-color: orange;
				}
			}

			// Large screens
			@media (min-width: #{$mediumBreakpoint + 1}) {
				.guide-offering-overview-large {
					display: grid;
					// background-color: green;
				}
			}

			// width: 100%;
			// // background-color: orange;
			// padding: 16px 0;
			// // height: 40px;

			.guide-name {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				// max-width: 200px;
				// align-items: center;
				// background-color: lightblue;
			}

			.external-trip-link-text {
				// background-color: lightcoral;
				display: inline-block;
				width: 120px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.detail-options {
				// background-color: antiquewhite;
				display: inline-flex;
				height: 100%;
				gap: 4px;

				.individual-option {
					// background-color: seagreen;
					align-content: center;
					// height: 100%;
					width: 18px;
				}
			}


			.logo-column {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.company-name-column {
				a {
					font-weight: 500;
					text-decoration: none;
					color: inherit;
					
					&:hover {
						text-decoration: underline;
					}
				}
			}
	
			.options-column {
				.detail-options {
					display: flex;
					gap: 8px;
				}
			}
	
			.review-column {
				display: flex;
				text-align: center;
				justify-content: center;
			}
	
			.price-column {
				font-weight: 600;
			}
	
			.link-column {
				display: flex;
				align-items: center;
				
				a {
					display: flex;
					align-items: center;
					text-decoration: none;
					color: inherit;
					
					&:hover {
						text-decoration: underline;
					}
				}
			}

			.guide-offering-overview-small {
				// display: grid;
				grid-template-columns: 40px 1fr 100px 80px;
				gap: 16px;
				align-items: center;
			}

			.guide-offering-overview-medium {
				// display: grid;
				grid-template-columns: 40px 1fr 240px 80px;
				gap: 16px;
				align-items: center;
			}

			.guide-offering-overview-large {
				// display: grid;
				grid-template-columns: 40px 200px 240px 180px 100px 1fr;
				gap: 16px;
				align-items: center;
				
			}
		}
	}
}

#itinerary-wrapper {

	#itinerary-description {
		// background-color: darkgoldenrod;
	}


	#itinerary-days {
		display: table;
		grid-template-columns: auto auto auto;
		// spacing between rows only
		border-spacing: 0 $spacing * 2;

		.itinerary-day-item {
			// background-color: bisque;
			display: table-row;


			.itinerary-day-number, .itinerary-day-remainder {
				display: table-cell;
			}
		

			.itinerary-day-number {
				// background-color: sandybrown;
				white-space: nowrap;
			}

			.itinerary-day-remainder {
				// background-color: rosybrown;
				width: 100%;
				padding-left: $spacing * 3;

				h4 {
					margin-bottom: 0;
				}

				.description {
					margin-top: $spacing;
				}

				.individual-day-stats {
					span {
						margin-right: $spacing * 2;

						&:last-of-type {
							margin-right: 0;
						}
					}
				}
			}
		}
	}
}

#services-wrapper {

	#service-items {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		grid-column-gap: $spacing;
		grid-row-gap: $spacing;
		margin-top: $spacing;

		.high-level-info-item {
			display: flex;
			// background-color: bisque;

			.icon {
				// background-color: sandybrown;
				stroke-width: 1;
			}

			.remainder {
				flex: 1;
				// background-color: rosybrown;
				padding-left: $spacing;

			}
		}
	}
}

#accommodation-wrapper {
	#accommodation-description {
		margin-bottom: $spacing * 3;
	}
}

#weather-wrapper {
	#myChart {
		max-width: 100%;
	}
}

#videos-wrapper {

	#videos-items {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		grid-column-gap: $spacing;
		grid-row-gap: $spacing;
		margin-top: $spacing;
	}
}
.video-wrapper {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%; // for 16:9 aspect ratio
	overflow: hidden;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

#variations-wrapper {
	display: grid;

	#variations-items {
		display: grid;
		grid-row-gap: $spacing * 3;

		.variations-item {

			h4 {
				font-size: 13.28px;
				
				svg {
					vertical-align: middle;
				}
			}

			.pics-column {
				.picture-group {
					max-width: 240px;

					.remaining-images {
						margin-top: $spacing;
					}

					img {
						cursor: pointer;
					}
				}
			}
		}
	}
}

#faqs-wrapper {

	#faqs-items {
		display: grid;
		grid-row-gap: $spacing * 4;

		.faqs-item {
			// background-color: bisque;

			h3.question {
				// background-color: sandybrown;
				margin-bottom: 0;
			}

			.answer {
				// background-color: rosybrown;
			}
		}
	}
}

#groups-wrapper {
	#groups-items {
		display: grid;
		grid-row-gap: $spacing;

		.groups-item {
			display: block;

			// ellipsis when too wide
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			-o-text-overflow: ellipsis;
		}
	}
}

#similar-wrapper {

	#similar-items {
		display: grid;
		grid-row-gap: $spacing;

		.similar-item {
			.similar-item-row {
				display: flex;
				flex-direction: row;
				// background-color: bisque;

				.pics-column {
					// background-color: sandybrown;
					flex-basis: 38%;
					// flex-shrink: 0;
					overflow: hidden;
				}

				.remainder {
					flex-basis: 62%;
					// flex-shrink: 0;
					// background-color: rosybrown;
					padding-left: $spacing;
				}
			}
		}
	}
}


#justified-gallery {
	.justified-row {
		margin-bottom: 8px;
		white-space: nowrap;
	}

	.justified-row img {
		max-height: 100%;
		margin-right: $spacing;
		border-radius: $spacing / 2;
		display: inline-block;
		cursor: pointer;
	}

	.justified-row img:last-child {
		margin-right: 0px;
	}
}

/* MISSING DATA PAGE */
.trip-list-table {

	tr {
		position: relative;

		td {
			vertical-align: top;

			&.trek-name {
				font-weight: bold;
				padding-right: $spacing * 2;
			}

			&.trek-type {
				white-space: nowrap;
				
				svg {
					vertical-align: middle;
				}
			}


			&.is-disabled {
				opacity: .25;
				cursor: not-allowed;
				position: relative;
		
				// td {
					position: relative;
	
					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						cursor: not-allowed;
						z-index: 1;
					}
				// }
			}
		}
	}
}

/* ADMIN SECTION */

.admin-container {
	padding: $spacing * 3;
	display: flex;

	.left-column {
		width: 120px;
		margin-right: 20px;
	}

	.right-column {
		flex-grow: 1;
	}
}
.pic-item {
	width: 110px;
	height: 110px;
	margin-right: 8px;
	margin-bottom: 8px;
	display: inline-block;
	opacity: 1;
	position: relative;
	border: 3px solid white;
	padding: 2px;

	&.active {
		opacity: 0.3;
	}

	&.first-five {
		border-color: lightcoral;
	}

	&:hover {
		.inset-control {
			display: block;
		}
	}

	.inset-control {
		position: absolute;
		display: none;
		z-index: 10;
		background-color: #fff;
		align-content: center;
		text-align: center;
		pointer-events: all;
		width: 32px;
		height: 32px;
		border-radius: 4px;

		svg {
			vertical-align: middle;
		}

		&.top-right {
			top: 4px;
			right: 4px;
		}

		&.bottom-right {
			right: 4px;
			bottom: 4px;
		}

		&.top-left {
			top: 4px;
			left: 4px;
		}

		&.anchor {
			cursor: move;
		}
	}
}

.uploads-edit {
	padding: $spacing;
	margin-bottom: $spacing * 2;
	position: relative;

	.upload-edit {
		margin-bottom: $spacing;

		img {
			max-width: 100%;
		}
	}
}

.is-first-five {
	border: 1px solid lightblue;
	border-radius: 8px;
	padding-top: 8px;
	padding-bottom: 8px;;
}
.text-column {
	text-overflow: clip;
}

.possible-map-data {
	.route-lines {
		
	}
	
	.possible-icons {
		ul {
			list-style-type: none; /* Remove default bullet points */
  			padding-left: $spacing / 2;

			li {
				display: inline-block;
				margin-right: $spacing;

				display: inline-block;
				margin-right: 15px; 
				position: relative;
				padding-left: 15px; /* Create space for bullet points */

				&::before {
					content: ""; /* Add a bullet point using pseudo-element */
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					width: 6px;
					height: 6px;
					border-radius: 50%;
					background-color: #000;
				}
			}
		}
	}

	li.is-added {
		text-decoration: line-through;
	}
}

.picture-select-component {
	
	.currently-selected {
		.currently-selected-picture {
			display: inline-block;
			margin-right: 4px;
			margin-bottom: 4px;
		}
	}
}

.picture-select-modal {

	.mantine-b95f0i {
		max-width: 100%;
	}

	.mantine-ScrollArea-viewport {
		div[style*="min-width: 100%"][style*="display: table;"] {
			max-width: 100% !important;
			min-width: 0 !important;
			display: block !important;
		}
	}

	.selectable-list {
		// todo: make this dynamic
		height: 400px;
		overflow-y: auto;
		padding-top: 8px;
		padding-bottom: 8px;
	}
	.picture-to-select {
		border: 2px solid white;
		border-radius: 4px;
		width: 115px;
		height: 115px;
		object-fit: cover;
		padding: 2px;
		margin-right: 2px;
		margin-bottom: 2px;	

		&.is-selected {
			border-color: brown;
		}
	}

	.selected-picture-line {
		overflow-x: auto;
		height: 70px;
		display: flex;
		flex-wrap: nowrap;
		max-width: 100%;

		.selected-picture {
			display: inline-block;
			margin-right: 4px;
		}
	}

	.button-space {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.right {
			margin-left: auto;
		}
	}
}

#route-visualisation-map {
	height: 550px;
}

// route edit section
#route-editing-ui {
	.container {
		display: grid;
		grid-template-columns: 1fr 1fr; /* Two columns, each taking up 50% width */
		grid-template-rows: 500px; /* Set height of the rows to approximately 400px */
		grid-gap: $spacing * 2; /* 20px gap between grid items */
	  }
	  
	  .right-column {
		display: grid;
		// grid-gap: 20px; 
		grid-template-rows: 1fr 1fr; /* Two rows, each taking up 50% height of the parent (right column) */
		grid-row-gap: $spacing;
	  }
	  
	  /* Optional: Add styles to the grid items for visual clarity */
	  .container > div {
		// background-color: #eaeaea; /* Background color for grid items */
		// padding: 10px; /* Add padding to grid items */
	  }

	#route-visualisation-map {
		height: 100%;
	}
}

.user-uploads-to-page {
	display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: $spacing; 

	.picture-container {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%; 
        border-radius: $spacing; 

        .picture-user-uploaded {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: inherit; 

			&.is-not-approved {
				opacity: .4;
			}
        }
    }
}

#uploads-section {
	table {
		table-layout: fixed;
		width: 100%;

		td {
			a {
				display: block;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100%;
			}

			img {
				max-width: 100%;
				height: auto;
			}
		}
	}
}

/*
GUIDE SECTION
*/
.guide-dashboard-menu {
	.mantine-NavLink-root {
		&[data-active=true] {
			color: white
		}
	}
}

.trip-offerings {
	margin-bottom: 24px;

	display: flex;
	flex-wrap: wrap; // Allow cards to wrap to the next line
	gap: 16px;
  
	.trip-offering-card {
		border: 2px dashed silver;
		border-radius: 8px;
		padding: 8px;
		display: flex;
		align-items: center;
		// width: calc(50% - 16px); // 16px as that is the gap in parent container
		width: 100%;
  
		&.is-offered {
			border: 2px solid $colorPrimary;
		}
  
		.trip-offering-content {
			display: flex;
			align-items: center;
			width: 100%;
		}
  
		.trip-details {
			display: flex;
			gap: $spacing * 4;
			flex-grow: 1;
			margin-left: 16px;
			align-items: center;

			.trip-title {
				font-weight: 500;
				font-size: x-large;
			}

			.trip-price {
				color: #b3490a;
				font-size: large;
			}
		}
  
		.edit-button {
			margin-left: auto;
		}
	}
}


.mce-inserted-image {
	img {
		min-width: 110%;
		margin-left: -5%;
		height: auto;
		margin-bottom: $spacing;
	}
}
// todo: make an mce-inserted youtube

.article-summary-list {
	display: flex;
  	flex-direction: column;
	padding: 0 $spacing;

	.article-summary {
		display: grid;
		grid-template-columns: 150px 1fr;
		gap: 20px;
		align-items: start;
		margin-bottom: $spacing * 4;
		position: relative;
		padding-bottom: $spacing * 2; 
	
		&.is-mobile {
			display: flex;
			flex-direction: column;
		
			.article-title {
				order: 1;
			}
	  
			.article-image {
				order: 2;
				width: 100%;
				height: auto;
				margin-top: 10px;
			}
	  
			.article-description {
				order: 3;
				margin-top: 10px;
		  	}
		}
	  
		.article-image {
			max-width: 150px;
			max-height: 100px;
			width: auto;
			height: auto; 
			object-fit: cover;
			margin: 0 auto;

			border-radius: $spacing * 1.5;

			&.is-mobile {
				max-width: 100%;
				max-height: unset;
			}
		}
	  
		.article-content {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
		}
	  
		.article-title {
			font-size: 1.5em;
			margin: 0;
			font-weight: 500;
			order: 1;
		}

		h3.article-subtitle {
			margin-bottom: $spacing / 2;
			font-weight: normal !important;
			order: 2;
		}
	  
		.article-description {
			margin-top: 10px;
			order: 3;
		}

		.article-tags {
			order: 4;
		}
	}
}

#article-page {
	width: 100%;
	margin-bottom: $spacing * 10;
  
	.hero {
		position: relative;
		width: 100%;
		height: 85vh;
		min-height: 500px;
		background-size: cover;
		background-position: center;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: $spacing * 5;
	
		.headline {
			color: #fff;
			font-weight: 700;
			text-align: center;
			padding: 60px 70px 100px;

			text-shadow: -1px -1px 8px #000;

			@media only screen and (max-width: 500px) {
				// color: pink;
				padding: 0px 0 150px;
			}

			box-sizing: border-box;
			position: absolute;
			width: 100%;
			bottom: 0;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, transparent 90%);
			z-index: 2;

			&::after {
				background: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1183 42.14'%3E%3Cpath fill='%23fff' d='M0,0S535,78.49,1183,0V42.14H0Z'/%3E%3C/svg%3E%0A") no-repeat center bottom;
				position: absolute;
				z-index: 2;
				left: 50%;
				// min-width: 550px;
				transform: translateX(-50%);
				content: '';
				bottom: -1px;
				width: 100%;
				backface-visibility: hidden;
				height: 50px;
			}
	
			.limit-w {
				max-width: 1000px;
				margin: 0 auto;
		
				.title-wrapper {

					max-height: 50vh;
					// background-color: red;
					// color: green;
		

					h1.hero-title {
						text-transform: capitalize;
						letter-spacing: 0;
						line-height: 140%;
						font-size: 47px;
						// font-family: var(--font-merriweather);

						@media (max-width: $mobileBreakpoint) {
							font-size: 32px;
							line-height: 120%;
						}
					}

					h2.hero-subtitle {
						font-size: 30px;
						margin-top: 10px;
						line-height: 140%;
						margin: 0 0 15px;
						font-weight: 300;
						text-transform: capitalize;
						letter-spacing: 0;
						// font-family: var(--font-merriweather);

						@media (max-width: $mobileBreakpoint) {
							font-size: 24px;
							line-height: 120%;
						}
					}
				}
			}
		}
	}
	.article-column {
		max-width: 800px;
		margin: 0 auto;
		padding-left: $spacing * 4;
		padding-right: $spacing * 4;

		@media (max-width: $mobileBreakpoint) {
			padding-left: $spacing *2;
			padding-right: $spacing*2;
		}

		.article-tags {
			// padding-left: $spacing * 4;
			// padding-right: $spacing * 4;

			margin-bottom: $spacing * 3;

			h4 {
				margin-bottom: $spacing / 2;
			}

			.tags-container {
				.article-tag {
					border: 1px solid orange;
					border-radius: 6px;
					padding: 8px;
					display: inline-block;
					margin-right: 4px;
					margin-bottom: 4px;
					text-transform: capitalize;
				}
			}
		}

		.article-content {

			img.article-image-replaced {
				margin-top: $spacing * 4;
				margin-bottom: $spacing * 4;
				border-radius: $spacing * 1.5;
			}

			.video-container {
				position: relative;
				width: 110%;
				padding-bottom: 61.875%; 
				height: 0;
				overflow: hidden;
				border-radius: $spacing * 1.5;
				margin-left: -5%;

				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border: 0;
				}
			}
		}
	}
}

#guide-slug-page {

}

.guide-directory-list {

	.title-flags-logo {
		display: flex;
		gap: $spacing;
		margin-bottom: $spacing;

		.logo {
			max-height: 80px;
			max-width: 80px;
			overflow: hidden;
		}

		.title-flags {

		}
	}

	#countries {
		display: flex;
		flex-wrap: wrap;
		gap: $spacing;
		margin-top: $spacing;
	}

	.trip-offering-prices {
		.small-trip-offering-summary {
			border: 1px solid silver;
			border-radius: 6px;
			padding: 4px;
			display: inline-block;
			margin-right: $spacing;
			margin-bottom: $spacing;
			align-items: center;

			img {
				margin-right: $spacing / 2;
			}

			.multipeak-trip-part {
				display: inline;
			}

			.multipeak-trip-part:not(:last-child)::after {
				content: '+';
				margin: 0 8px;
			}

			&.highlighted {
				border-color: $colorPrimary;
				// background-color: beige;
				border-width: 2px;
				background-color: $colorAccent;
				color: $colorPrimary;
				font-weight: bold;
			}
		}
	}
}

.country-template {
	padding-bottom: 80px;
}

.route-map-wrapper {
	max-width: 100%;

	img {
		max-width: 100%;
		height: auto;
		border-radius: $spacing / 2;
	}
}

.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 4px dashed;
	border-top: 4px solid;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
}

/*
responsive helpers
*/

.mobile-show {
	display: none;

	@media (max-width: $mobileBreakpoint) {
		display: block;
	}
}

.mobile-hide {
	display: block;

	@media (max-width: $mobileBreakpoint) {
		display: none;
	}
}

a.brand-title {
	display: flex;
	align-items: center;
	gap: 8px;

	padding: 9px $spacing $spacing $spacing;

	font-size: 22px;
	font-weight: 500;
	line-height: $headerInner;
	vertical-align: top;
	color: black;
	text-transform: capitalize;
}

#header2 {
	height: 60px;
	padding: 0 var(--mantine-spacing-md);
	border-bottom: 1px solid #dee2e6;
	font-family: $font-geologica;

	.mantine-Text-root{
		font-family: $font-geologica;
	}

	header {
		height: 100%;
	}

	.header-link {
		padding-top: 2px;
		border-bottom: 2px solid transparent;

		&.active-link {
			// text-decoration: underline; /* or any other styles you want */
			font-weight: 500; /* Optional: make it bold */
			border-bottom: 2px solid $colorPrimary;
		}
	}

	

	.subLink {
		width: 100%;
		padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
		border-radius: var(--mantine-radius-md);
		color: black;
	  
		&:hover {
			background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-7));
		}
	}
}
a.header-link, a.header-link:hover, a.header-link:visited, a.header-link:active {
	color: black;
	font-weight: 500;
	font-size: 14px;

	@media (max-width: $mobileBreakpoint) {
		height: rem(42px);
		width: 100%;
	}
  
	&:hover {
		background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
	}
}


//
// INDIVIDUAL COMPONENTS
// 
.country-badge {
	border: 1px solid silver;
	border-radius: 6px;
	padding: 4px;
	font-size: 14px;
	display: flex;
	align-items: center;
	
	img.flag {
		margin: $spacing / 2;
		// margin-top: 0px;
		height: 14px;
		border-radius: 2px;
	}

	&.highlighted {
		border-color: $colorPrimary;
		background-color: $colorAccent;
		color: $colorPrimary;
		border-width: 2px;
		font-weight: 700;
	}
}

.trip-advisor-widget-wrapper {

	// #CDSRATINGWIDGET878 {
	// 	max-width: unset;
	// }

	.cdsROWContainer {
		width: 468px !important;
	}

	#CDSROWLOC878 {
		max-width: 100% !important;
	}
}

button.custom-accordion-label {
	padding-left: 0;
	padding-right: 0;
	span.mantine-Accordion-label {
		padding: 0 !important;
	}

	.mantine-Accordion-chevron {
		margin-left: 8px;
	}
}

.mountain-reviews-section {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	gap: 24px;
	margin-bottom: 32px;

	.mountain-review-card {
		background: #f8f9fa;
		border-radius: 8px;
		padding: 24px;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
		transition: transform 0.2s ease;

		&:hover {
			transform: translateY(-2px);
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
		}

		.review-content {
			display: flex;
			flex-direction: column;
			gap: 16px;
		}

		.review-meta {
			margin-top: auto;
		}
	}
}
